<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import {
    getCampaignDays,
    convertToDateFromString,
    parseDayFromString,
  } from "../utils";

  import { getFragmentConfigOr } from "~/components/field-utils";
  import { ANY_DAY, filter_by_day } from "../store";
  import { propsToCustomProperties } from "~/util/style";
  import Dropdown from "./Dropdown.svelte";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;

  const stores_context = getStoresContext(request_store_key);
  const { locale, T, offers_ext } = stores_context;

  type Config = {
    day_filter_color: string;
    day_filter_bg_color: string;
    day_filter_active_color: string;
    day_filter_active_bg_color: string;
  }

  const config: Config = {
    day_filter_color: 
      getFragmentConfigOr("textColor", fragment, "var(--brandColor1)"),
    day_filter_bg_color: 
      getFragmentConfigOr("backgroundColor", fragment, "var(--brandColor2)"),
    day_filter_active_color: 
      getFragmentConfigOr("activeTextColor", fragment, "var(--brandColor2)"),
    day_filter_active_bg_color: 
      getFragmentConfigOr("activeBackgroundColor", fragment, "var(--brandColor1)")
  }

  $: ({ items } = $offers_ext);

  $: dayFilters = items && getCampaignDays(items);
  $: t = $T;

  function action(day: string) {
    filter_by_day.set(day);
  }
</script>

{#if $locale && items && items.length}
  <div
    class="container" style={propsToCustomProperties(config)}>
    <div
      class="day-filters d-md-flex justify-content-center d-none d-md-block w-100 mb-4">
      <button
        class:active={$filter_by_day == ANY_DAY}
        on:click={() => action(ANY_DAY)}>
        {t`offers.allDays`}
      </button>
      {#if dayFilters}
        {#each dayFilters as day}
          <button
            data-date={day}
            class:active={$filter_by_day == day}
            on:click={() => action(day)}>
            {$locale && parseDayFromString(day, $locale, "long")}
            {convertToDateFromString(day, $locale)}
          </button>
        {/each}
      {/if}
    </div>
    <Dropdown color={config.day_filter_active_color} bg={config.day_filter_active_bg_color}>
      <svelte:fragment slot="dropDownTitle">
          {$filter_by_day == ANY_DAY ? t`offers.allDays` : parseDayFromString($filter_by_day, $locale, "long") + " " + convertToDateFromString($filter_by_day, $locale)}
      </svelte:fragment>
      <svelte:fragment slot="resetFilter">
        <button class="dropdown-item-button" on:click={() => action(ANY_DAY)}>
          {t`offers.allDays`}
        </button>
      </svelte:fragment>
      <svelte:fragment slot="dropDownItems">
        {#if dayFilters}
          {#each dayFilters as day}
            <div class="dropdown-item">
              <button class="dropdown-item-button" 
                class:active={$filter_by_day == day}
                on:click={() => action(day)}>
                {$locale && parseDayFromString(day, $locale, "long")}
                {convertToDateFromString(day, $locale)}
              </button>
            </div>
          {/each}
        {/if}
      </svelte:fragment>
    </Dropdown>
  </div>
{/if}

<style lang="scss">
  .dropdown-item-button{
    display: block;
    width: 100%;
    background: none;
    border: none;
    color: var(--day_filter_color);
    background-color: var(--day_filter_bg_color);

  }
  .dropdown-item-button.active{
    color: var(--day_filter_active_color, #fff);
    background-color: var(--day_filter_active_bg_color, #000);
  }

  .day-filters {
    flex-wrap: wrap;

    button {
      padding: 10px;
      border: none;
      color: var(--day_filter_color);
      background-color: var(--day_filter_bg_color);
      font-weight: 600;
      text-transform: uppercase;
    }

    button.active,
    button:hover {
      color: var(--day_filter_active_color, #fff);
      background-color: var(--day_filter_active_bg_color, #000);
      text-decoration: underline;
    }

    @media only screen and (max-width: 576px) {
      button {
        width: 90vw;
        margin: 2px;
        text-align: center;
      }
    }
  }

</style>
